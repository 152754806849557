:root {
  --securely-1: #251b65;
  --securely-2: #382999;
  --securely-3: #5e45ff;
  --securely-4: #9584ff;
  --white: white;
  --alternative-white: #f9f9f9;
  --superficial: grey;
}

body {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  height: 75px;
}

#login {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  padding: 15px;
}

td {
  vertical-align: middle;
}

#payment-link-control {
  color: #000000bf;
}

#payment-link-control:hover {
  cursor: pointer;
  color: #000000e6;
}

#payment-link-control:active {
  color: #000;
}

.pretty-address:hover {
  cursor: pointer;
  color: #000000e6;
}

.toast {
  width: min-content !important;
  min-width: 350px !important;
}

.superficial {
  white-space: nowrap;
  opacity: .75;
  color: var(--superficial) !important;
}

.nowrap {
  text-wrap: nowrap;
  white-space: nowrap;
}

td {
  padding: .5rem .4rem !important;
}

td:first-of-type {
  padding-left: .5rem !important;
}

td:last-of-type {
  padding-right: .5rem !important;
}

main {
  margin-bottom: 80px !important;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.container-fluid, .row, .col {
  margin: 0;
  padding: 0;
}

header {
  background-color: var(--alternative-white);
  width: 100%;
  margin: 0;
}

header > h1 {
  background-image: linear-gradient(-296deg, var(--securely-2), var(--securely-4));
  padding: 1rem;
  color: var(--white) !important;
}

.balances-row {
  background-color: var(--alternative-white);
}

.history-row {
  background-color: var(--white);
}

.balances-col, .history-col {
  max-width: 700px;
}

footer {
  background-color: var(--alternative-white);
}

h1, h2, h3, h4, h5, h6 {
  font-family: Roboto Condensed, Helvetica Neue, Arial, sans-serif;
}

a {
  color: var(--securely-3);
  text-decoration: none;
}

a:hover {
  color: var(--securely-1);
  text-decoration: underline;
}

.thead > * > * {
  background-color: var(--securely-4) !important;
  color: var(--white) !important;
}

.table-striped-custom tbody tr:nth-of-type(odd) td {
  background-color: var(--alternative-white) !important;
}
/*# sourceMappingURL=index.8f255e7b.css.map */
