:root {
    --securely-1: #251B65;
    --securely-2: rgb(56, 41, 153);
    --securely-3: #5E45FF;
    --securely-4: rgb(149, 132, 255);
    --white: white;
    --alternative-white: #f9f9f9;
    --superficial: grey;
}

body {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.logo {
    height: 75px;
}

#login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

td {
    vertical-align: middle;
}

#payment-link-control {
    color: rgba(0, 0, 0, 0.75);
}

#payment-link-control:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
}

#payment-link-control:active {
    color: rgba(0, 0, 0, 1);
}

.pretty-address:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
}

.toast {
    width: min-content !important;
    min-width: 350px !important;
}

.superficial {
    color: var(--superficial) !important;
    white-space: nowrap;
    opacity: 0.75;
}

.nowrap {
    text-wrap: nowrap;
    white-space: nowrap;
}

td {
    padding: .5rem .4rem !important;
}

td:first-of-type {
    padding-left: .5rem !important;
}

td:last-of-type {
    padding-right: .5rem!important;
}

main {
    margin-bottom: 80px !important; /* Footer height */
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.container-fluid, .row, .col {
    padding: 0;
    margin: 0;
}

header {
    width: 100%;
    margin: 0;
    background-color: var(--alternative-white);
}

header>h1 {
    background-image: linear-gradient(-296deg, var(--securely-2), var(--securely-4));
    color: var(--white) !important;
    padding: 1rem;
}

.balances-row {
    background-color: var(--alternative-white);
}

.history-row {
    background-color: var(--white);
}

.balances-col, .history-col {
    max-width: 700px;
}

footer {
    background-color: var(--alternative-white);
}

h1, h2, h3, h4, h5, h6 {
    font-family: Roboto Condensed,Helvetica Neue,Arial,sans-serif
}

a {
    color: var(--securely-3);
    text-decoration: none;
}

a:hover {
    color: var(--securely-1);
    text-decoration: underline;
}

.thead>*>* {
    background-color: var(--securely-4) !important;
    color: var(--white) !important;
}

.table-striped-custom tbody tr:nth-of-type(odd) td {
    background-color: var(--alternative-white) !important;
}
